<template>
  <div>
    <div class="corners">
      <div class="corners__header">
        {{ headerTitle }}
      </div>
      <DataByPeriod />
      <br />
      <div v-if="colorsConfig.show_logo && showLogos" class="bet-logo">
        <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
      </div>
      <div class="corners__per-minutes">
        <CornersAndGoalsPerMinute :key="perMinuteKey" />
      </div>
      <br />
      <div v-if="colorsConfig.show_logo && showLogos" class="bet-logo">
        <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
      </div>
      <div v-if="!selectedGame.isCup" class="corners__last-five-games">
        <LastFiveGames :teams-data="teamsData" />
      </div>
    </div>

    <div v-if="selectedGame.hasBets && areBetsVisible" class="betpergoas-bets">
      <BetBets :game-id="selectedGame.game_id" :type="2" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Corners',
  components: {
    CornersAndGoalsPerMinute: () => import('@/components/Sections/GolTipster-v2/General/CornersAndGoalsPerMinute'),
    BetBets: () => import('@/components/Sections/GolTipster-v2/General/BetBets'),
    DataByPeriod: () => import('@/components/Sections/GolTipster-v2/General/DataByPeriod'),
    LastFiveGames: () => import('@/components/Sections/GolTipster-v2/General/LastFiveGames'),
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  props: {
    isCornersGoalsMobile: {
      type: Boolean,
      default: false,
    },
    showLogos: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      perMinuteKey: 200,
      cornersRows: [
        {
          text: '',
          gameProp: 'rival_logo',
          type: 0,
        },
        {
          text: 'Tiros de esquina\n Totales:',
          gameProp: ['corners', 'first_time_corners', 'second_time_corners'],
          type: 1,
        },
      ],
      areBetsVisible: false,
    };
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame', 'filterGamePeriod', 'rightBetComponent', 'refreshIntervalCounter']),
    ...mapState('profileGeneral', ['colorsConfig']),
    ...mapGetters('betsGeneral', ['lastFiveGames']),
    headerTitle() {
      return this.isCornersGoalsMobile ? 'Tiros de esquina por tiempos' : 'Tiros de Esquina';
    },
    teamsData() {
      return {
        homeTeam: {
          logo: this.selectedGame.homeTeam.logo,
          name: this.selectedGame.homeTeam.colloquial_name,
          tableRows: this.getTableRows(this.lastFiveGames.homeTeam, this.selectedGame.homeTeam.team_id),
        },
        awayTeam: {
          logo: this.selectedGame.awayTeam.logo,
          name: this.selectedGame.awayTeam.colloquial_name,
          tableRows: this.getTableRows(this.lastFiveGames.awayTeam, this.selectedGame.awayTeam.team_id),
        },
      };
    },
  },
  watch: {
    rightBetComponent(newValue) {
      if (newValue === 'Corners') {
        this.perMinuteKey += 1;
      }
    },
    selectedGame() {
      this.setAreBetVisible();
    },
    refreshIntervalCounter: {
      immediate: true,
      handler() {
        this.setAreBetVisible();
      },
    },
  },
  methods: {
    getTableRows(games, teamId) {
      if (!games || !teamId) {
        return [];
      }
      return this.cornersRows.reduce((accumulator, row) => {
        const rowAux = {
          type: row.type,
          values: [row.text],
        };
        games.forEach(game => {
          rowAux.values.push(game[this.getGameProp(row.gameProp)]);
        });
        accumulator.push(rowAux);
        return accumulator;
      }, []);
    },
    getGameProp(gameProp) {
      return Array.isArray(gameProp) ? gameProp[this.filterGamePeriod] : gameProp;
    },
    setAreBetVisible() {
      const betSectionsLimit = Date.now() + 60 * 1000;
      const gameDateTime = new Date(this.selectedGame.date_time_utc).valueOf();
      this.areBetsVisible = gameDateTime >= betSectionsLimit;
    },
  },
};
</script>

<style scoped lang="scss">
.corners {
  padding: 0 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    border-radius: 7px;
    background-color: #132839;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    color: white;
    padding: 0.25rem;
    margin: 3rem 0 1rem;
  }
}
.betperesult-bets {
  width: 100%;
  height: auto;
  display: inline-flex;
  justify-content: center;
}
.bet-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}
</style>
